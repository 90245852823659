@tailwind base;
@tailwind components;
@tailwind utilities;

::-moz-selection {
    background-color: #d02ed0;
    color: #241823;
}

::selection {
    background-color: #d02ed0;
    color: #241823;
}

body {
    margin: 0;
    background-color: #241823;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.object {
    animation: MoveUpDown 15s infinite;
    position: absolute;
}

@keyframes MoveUpDown {
    0%,
    100% {
        transform: translateY(25px);
    }
    50% {
        transform: translateY(-25px);
    }
}

.gradient {
    background: radial-gradient(circle, #b429b4 0%, #241823 70%);
}
/* 
.zoomOnHover {
    transition: transform 0.3s ease-in-out;
}

.zoomOnHover:hover {
    transform: scale(1.2);
    cursor: pointer;
    border: 1px solid #b429b4;
    z-index: 20;
} */
.trapezoid {
    clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%);
    background-color: #d02ed0;
}

.innerTrapezoid {
    clip-path: polygon(41% 2%, 100% 2%, 100% 100%, 2% 100%);
    background-color: #241823;
}
